import React from "react";
import { SplitPanel } from "../Dashboard";
import {
  withEmailVerification,
  AuthUserContext,
  withAuthorization,
} from "../Session";
import { withFirebase } from "../Firebase";
import AddProducts from "./AddProducts";
import AlertList from "./AlertList";
import ProductList from "./ProductList";
import Settings from "./Settings";
import { compose } from "recompose";
import { Route, Link, Switch } from "react-router-dom";

import {
  BellOutlined,
  SettingOutlined,
  ShoppingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Menu } from "antd";

function MonitoringDashboard(props) {
  let locations = {
    "/monitoring": "products",
    "/monitoring/products": "products",
    "/monitoring/alerts": "alerts",
    "/monitoring/products/add": "addproducts",
    "/monitoring/settings": "settings",
  };
  let selected = [locations[props.location.pathname]];

  return (
    <SplitPanel>
      <AuthUserContext.Consumer>
        {(authUser) => (
          <>
            <Menu mode="horizontal" selectedKeys={selected}>
              <Menu.Item key="products">
                <Link to={`${props.match.url}/products`}>
                  <ShoppingOutlined /> Products
                </Link>
              </Menu.Item>
              <Menu.Item key="alerts">
                <Link to={`${props.match.url}/alerts`}>
                  <BellOutlined /> Alerts
                </Link>
              </Menu.Item>
              <Menu.Item key="addproducts">
                <Link to={`${props.match.url}/products/add`}>
                  <PlusOutlined /> Add Products
                </Link>
              </Menu.Item>
              <Menu.Item key="settings">
                <Link to={`${props.match.url}/settings`}>
                  <SettingOutlined /> Settings
                </Link>
              </Menu.Item>
            </Menu>
            <Switch>
              <Route
                exact
                path={`${props.match.path}/products/add`}
                component={() => <AddProducts authUser={authUser} />}
              />
              <Route
                exact
                path={`${props.match.url}`}
                component={() => <ProductList authUser={authUser} />}
              />
              <Route
                path={`${props.match.url}/products`}
                component={() => <ProductList authUser={authUser} />}
              />
              <Route
                exact
                path={`${props.match.path}/alerts`}
                component={() => <AlertList authUser={authUser} />}
              />
              <Route
                exact
                path={`${props.match.path}/settings`}
                component={() => <Settings authUser={authUser} />}
              />
            </Switch>
          </>
        )}
      </AuthUserContext.Consumer>
    </SplitPanel>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withFirebase,
  withAuthorization(condition)
)(MonitoringDashboard);
