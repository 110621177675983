import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import SignUp from "./components/SignUp";
import SignInPage from "./components/SignIn";
import AccountPage from "./components/Account";
import MonitoringDashboard from "./components/Monitoring";
import Billing, { Plans } from "./components/Billing";
import { Changelog } from "./components/Utils";

import Help from "./components/Help";
import { withAuthentication } from "./components/Session";
import { Result, Button } from "antd";
import PasswordForgetPage from "./components/PasswordForget";

const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Button type="primary">
        <Link to="/">Back Home</Link>
      </Button>
    }
  />
);

const App = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/monitoring" component={MonitoringDashboard} />
        <Route path="/account" component={AccountPage} />
        <Route path="/signup" component={SignUp} />
        <Route path="/signin" component={SignInPage} />
        <Route path="/billing" component={Billing} />
        <Route path="/plans" component={Plans} />
        <Route path="/password-reset" component={PasswordForgetPage} />
        <Route path="/help" component={Help} />
        <Route path="/changelog" component={Changelog} />
        <Route path="*" render={NotFound} />
      </Switch>
    </Router>
  );
};

export default withAuthentication(App);
