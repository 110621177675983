const plans = [
  {
    name: "Starter 25",
    asins: 25,
    id: "starter_25_monthly",
    price: "13.95",
    color: "blue",
    marketplaces: "All Supported Marketplaces",
  },
  {
    name: "Basic 50",
    asins: 50,
    id: "basic_50_monthly",
    price: "22.95",
    color: "green",
    marketplaces: "All Supported Marketplaces",
  },
  {
    name: "Basic 100",
    asins: 100,
    id: "basic_100_monthly",
    price: "34.95",
    color: "orange",
    marketplaces: "All Supported Marketplaces",
    extras: ["1 Extra Notification E-mail"],
  },
  {
    name: "Basic 250",
    asins: 250,
    id: "basic_250_monthly",
    price: "69.00",
    color: "volcano",
    marketplaces: "All Supported Marketplaces",
    extras: ["2 Extra Notification E-mails"],
  },
  {
    name: "Pro 1000",
    asins: 1000,
    id: "pro_1000_monthly",
    price: "199.00",
    color: "magenta",
    marketplaces: "All Supported Marketplaces",
    extras: [
      "6 Extra Notification E-mails",
      "Personal Onboarding",
      "Account Manager",
    ],
  },
  {
    name: "Pro 2500",
    asins: 2500,
    id: "pro_2500_monthly",
    price: "395.00",
    color: "magenta",
    marketplaces: "All Supported Marketplaces",
    extras: [
      "12 Extra Notification E-mails",
      "Personal Onboarding",
      "Account Manager",
    ],
  },
  {
    name: "Enterprise 5000",
    asins: 5000,
    id: "enterprise_5000_monthly",
    price: "649.00",
    color: "black",
    marketplaces: "All Supported Marketplaces",
    extras: [
      "25 Extra Notification E-mails",
      "Personal Onboarding",
      "Account Manager",
    ],
  },
];

export default plans;
