import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import SplitScreen from "../Utils/SplitScreen";
import { Form, Input, Button, Alert } from "antd";

import { SignInGoogle } from "../SignIn";
import { SignInLink } from "../SignIn";
import { LockOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";
const SignUpPage = () => (
  <SplitScreen>
    <div>
      <img
        alt="Datanamo"
        src={require("../Utils/logo-dark.svg")}
        style={{
          width: "100px",
          marginBottom: "40px",
        }}
      />
      <SignUpForm>
        <SignInGoogle />
        <br />
        <SignInLink />
      </SignUpForm>
    </div>
  </SplitScreen>
);

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  isAdmin: false,
  error: null,
  loading: false,
};

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { username, email, passwordOne } = this.state;
    // this.setState({ loading: true });
    const roles = {};

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        return this.props.firebase.user(authUser.user.uid).set(
          {
            username,
            email,
            roles,
          },
          { merge: true }
        );
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.DASHBOARD);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    // this.setState({ loading: false });

    // event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const { email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === "" || email === "";

    return (
      <>
        <Form
          onFinish={this.onSubmit}
          className="login-form"
          style={{ width: "300px", margin: "0 auto" }}
        >
          <h1>Sign Up</h1>
          {error && (
            <Form.Item style={{ maxWidth: "300px" }}>
              <Alert
                message="Error"
                description={error.message}
                type="error"
                style={{ margin: "0 20px" }}
                showIcon
              />
            </Form.Item>
          )}
          <Form.Item style={{ maxWidth: "300px" }}>
            <Input
              name="email"
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
          </Form.Item>
          <Form.Item style={{ maxWidth: "300px" }}>
            <Input
              name="passwordOne"
              value={passwordOne}
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item style={{ maxWidth: "300px" }}>
            <Input
              name="passwordTwo"
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm Password"
            />
          </Form.Item>
          <Button
            style={{ width: "300px", maxWidth: "300px" }}
            type="submit"
            disabled={isInvalid || this.state.loading}
            htmlType="submit"
            className="login-form-button"
          >
            Sign Up
            {this.state.loading ? (
              <LoadingOutlined style={{ fontSize: 16 }} spin />
            ) : (
              ""
            )}
          </Button>

          {this.props.children}
        </Form>
      </>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
