import React from "react";

import AuthUserContext from "./context";
import { Alert, Button } from "antd";
import { withFirebase } from "../Firebase";
import SplitScreen from "../Utils";

const needsEmailVerification = (authUser) =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map((provider) => provider.providerId)
    .includes("password");

const withEmailVerification = (Component) => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            needsEmailVerification(authUser) ? (
              <SplitScreen>
                {this.state.isSent ? (
                  <Alert
                    type="info"
                    showIcon
                    message="
                    E-Mail confirmation sent: check you e-mails (spam folder
                    included) for a confirmation e-mail. Refresh this page once
                    you confirmed your e-mail."
                  />
                ) : (
                  <Alert
                    type="info"
                    showIcon
                    message="
                    Verify your e-mail: Check you e-mails (spam folder included)
                    for a confirmation e-mail or send another confirmation
                    e-mail."
                  />
                )}
                <Button
                  type="button"
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}
                  style={{ marginTop: "20px" }}
                >
                  Resend the confirmation e-email
                </Button>
              </SplitScreen>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
