import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  message,
  Button,
  Col,
  Form,
  Input,
  Tooltip,
  Checkbox,
  Skeleton,
  Slider,
} from "antd";
import {
  QuestionCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { compose } from "recompose";
import { withEmailVerification, withAuthorization } from "../Session";
import { renderAlertType } from "../Utils";
import { AlertEmails } from "./SettingsNotificationEmails";

function Settings(props) {
  const [userPref, setUserPref] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = getPreferences();
    return () => unsubscribe();
  }, []);

  const getPreferences = () => {
    return props.firebase.db
      .collection("users")
      .doc(props.authUser.uid)
      .onSnapshot(handleSnapshot, function (err) {
        setError(err.message);
      });
  };

  function handleSnapshot(snapshot) {
    try {
      let prefs = snapshot.data().preferences;
      setUserPref(prefs);
    } catch (err) {
      setError(err.message);
    }
  }

  const onChange = (e) => {
    if (
      e.target.name === "product_bsr_main" ||
      e.target.name === "product_bsr_sub_categories"
    ) {
      const prefs = userPref[e.target.name].slice();
      prefs[0] = e.target.checked;
      setUserPref({ ...userPref, [e.target.name]: prefs });
    } else {
      setUserPref({ ...userPref, [e.target.name]: e.target.checked });
    }
  };

  const setBSR = (value, name) => {
    const prefs = userPref[name].slice();
    prefs[1] = value;
    setUserPref({ ...userPref, [name]: prefs });
  };

  const handleSave = () => {
    props.firebase.db
      .collection("users")
      .doc(props.authUser.uid)
      .update({ preferences: userPref })

      .then((res) => {
        message.info("Alert preferences saved");
      })
      .catch((err) => message.error("Could not update preferences"));
  };

  const renderHelp = (k) => {
    let helpDict = {
      product_bsr_main:
        "Changes to best sellers rank in product's main category",
      product_sellers: "Alert when new sellers start selling monitored product",
      product_main_category: "Alert when product changes main category",
      product_bsr_sub_categories:
        "Changes to product's rank in its subcategories",
      product_title: "Changes to product's title",
      product_brand: "Changes to product's brand/manufacturer name",
      product_rating: "Alert when product gains or loses star rating",
      product_reviews: "Alert when product gains or loses reviews",
      product_questions:
        "Alert when product's 'answered quesions' number changes",
      product_price: "Changes to product's price",
      product_availability: "Alert when product's availability changes",
      product_buybox: "Alert whenever product's buybox changes its owner",
      product_suppressed:
        "Alert whenever Amazon removes buybox from a product listing",
      product_main_image: "Alert when product's main image changes",
      product_image_count: "Alert when total number of images changes",
      product_bullet_points: "Alert when listing bullet points change",
      product_badge: "Alert when product gains/loses Amazon badge",
      product_dimensions: "Alert when product's dimensions change",
      product_description: "Alert when listing's main description changes",
      product_review_most_helpful_positive:
        "Alert when product's most helpful, positive review changes",
      product_review_most_helpful_negative:
        "Alert when product's most helpful, negative review changes",
      product_item_weight: "Alert when product's item weight changes",
      product_map_violation:
        "Alert when one of the listing's offers for new product has a significantly lower price than average",

      product_shipping_weight: "Alert when product's shipping weight changes",
      enhanced_brand_content:
        "Alert when product's description changes to Enhanced Brand Content",
      alerts_daily_summary: "Send daily summary of all the changes",
    };
    return helpDict[k] ? helpDict[k] : "";
  };

  return (
    <div style={{ marginTop: "3vh" }}>
      <h3>Settings</h3>
      {error && <span>{error}</span>}
      <Row>
        <Col xxl={6} xl={8} lg={10} md={12} sm={24} xs={24}>
          {userPref ? (
            <Card
              title={
                <>
                  <h4>Alerts</h4>
                  <Button type="primary" onClick={handleSave}>
                    Save
                  </Button>
                </>
              }
              bordered={false}
            >
              {Object.keys(userPref).map((k) => (
                <div
                  key={k}
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <>
                    <Checkbox
                      name={k}
                      defaultChecked={
                        k === "product_bsr_main" ||
                        k === "product_bsr_sub_categories"
                          ? userPref[k][0]
                          : userPref[k]
                      }
                      checked={
                        k === "product_bsr_main" ||
                        k === "product_bsr_sub_categories"
                          ? userPref[k][0]
                          : userPref[k]
                      }
                      onChange={onChange}
                    >
                      {renderAlertType(k)}{" "}
                      <Tooltip placement="right" title={renderHelp(k)}>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Checkbox>
                    {(k === "product_bsr_main" ||
                      k === "product_bsr_sub_categories") && (
                      <Slider
                        tipFormatter={(v) => {
                          return v === 0 ? `any change` : `${v}% change`;
                        }}
                        defaultValue={userPref[k][1]}
                        // value={userPref[k][1]}
                        disabled={!userPref[k][0]}
                        onAfterChange={(e) => setBSR(e, k)}
                        name={k}
                      />
                    )}
                  </>
                </div>
              ))}
            </Card>
          ) : (
            <Skeleton active />
          )}
        </Col>
        <Col xxl={6} xl={8} lg={10} md={12} sm={24} xs={24}>
          <AlertEmails firebase={props.firebase} authUser={props.authUser} />
        </Col>
      </Row>
    </div>
  );
}

const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(Settings);
