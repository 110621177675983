import React, { useState, useEffect } from "react";
import { Card, Button, Form, message, Input, Skeleton } from "antd";
import {
  QuestionCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const withoutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    mg: { span: 24, offset: 0 },
  },
};

const AlertEmails = (props) => {
  const [error, setError] = useState(null);
  const [emails, setEmails] = useState(null);

  const onFinish = (values) => {
    const { emails } = values;
    return props.firebase.db
      .collection("users")
      .doc(props.authUser.uid)
      .update({ emails: emails })
      .then((res) => {
        message.info("Notification Emails saved");
      })
      .catch((err) => message.error("Could not update preferences"));
  };

  useEffect(() => {
    const unsubscribe = getPreferences();
    return () => unsubscribe();
  }, []);

  const getPreferences = () => {
    return props.firebase.db
      .collection("users")
      .doc(props.authUser.uid)
      .onSnapshot(handleSnapshot, function (err) {
        setError(err.message);
      });
  };

  function handleSnapshot(snapshot) {
    try {
      let prefs = snapshot.data().emails;
      setEmails(prefs ? prefs : []);
    } catch (err) {
      setError(err.message);
    }
  }

  return (
    <>
      {emails ? (
        <>
          <Form
            name="notificationEmails"
            {...withoutLabel}
            onFinish={onFinish}
            initialValues={{ emails: emails }}
          >
            <Card
              title={
                <>
                  <h4>Notification Emails</h4>
                  <Button htmlType="submit" type="primary">
                    Save
                  </Button>
                </>
              }
              bordered={false}
            >
              <Form.List name="emails">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <Form.Item
                          {...withoutLabel}
                          required={false}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            type="email"
                            value={field.value}
                            rules={[
                              {
                                required: true,
                                whitespace: true,

                                message:
                                  "Please enter an email or delete this field.",
                              },
                              {
                                type: "email",
                                message: "Please enter a valid email",
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              placeholder="E-mail"
                              value={field.value}
                              style={{ width: "60%" }}
                            />
                          </Form.Item>

                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{ margin: "0 8px" }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={(v) => {
                            add();
                          }}
                          disabled={fields.length > 1 ? true : false}
                          style={{ width: "60%" }}
                        >
                          <PlusOutlined /> Add new e-mail
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </Card>
          </Form>
        </>
      ) : (
        <Skeleton active />
      )}
    </>
  );
};

export { AlertEmails };
