import React, { Component, useState, useEffect } from "react";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
import { withFirebase } from "../Firebase";
import PasswordChangeForm from "../PasswordChange";
import { SplitPanel } from "../Dashboard";
import { Input, Form, Button } from "antd";
import { KeyOutlined, GoogleOutlined } from "@ant-design/icons";

const SIGN_IN_METHODS = [
  {
    id: "password",
    provider: null,
  },
  {
    id: "google.com",
    provider: "googleProvider",
  },
];

const UserData = (props) => {
  const [userData, setUserData] = useState(props.authUser);
  useEffect(() => {
    return props.firebase.user(props.authUser.uid).onSnapshot((snapshot) => {
      setUserData(snapshot.data());
    });
  }, []);

  return (
    <>
      {/* <div>{userData.username ? userData.username : ""}</div> */}
      <div>
        <h1>
          Products Tracked:{" "}
          {userData.productsTracked ? userData.productsTracked : 0}
        </h1>
        {/* ts: {props.authUser.createdAt} */}
        {/* <pre>{JSON.stringify(props.authUser, undefined, 2)}</pre> */}
      </div>
    </>
  );
};

const AccountPage = (props) => {
  return (
    <SplitPanel>
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div>
            <h1>
              Account: {authUser.email} <hr></hr>
              Verified:{" "}
              <span
                style={{
                  color: authUser.emailVerified ? "green" : "red",
                }}
              >
                {authUser.emailVerified ? "yes" : "no"}
              </span>
            </h1>
            <UserData authUser={authUser} firebase={props.firebase} />

            <PasswordChangeForm />
            <LoginManagement authUser={authUser} />
          </div>
        )}
      </AuthUserContext.Consumer>
    </SplitPanel>
  );
};

class LoginManagementBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSignInMethods: [],
      error: null,
    };
  }

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods = () => {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then((activeSignInMethods) =>
        this.setState({ activeSignInMethods, error: null })
      )
      .catch((error) => this.setState({ error }));
  };

  onSocialLoginLink = (provider) => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch((error) => this.setState({ error }));
  };

  onDefaultLoginLink = (password) => {
    const credential = this.props.firebase.emailAuthProvider.credential(
      this.props.authUser.email,
      password
    );

    this.props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch((error) => this.setState({ error }));
  };

  onUnlink = (providerId) => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch((error) => this.setState({ error }));
  };

  render() {
    const { activeSignInMethods, error } = this.state;

    return (
      <div>
        <h1>Authentication methods:</h1>

        {SIGN_IN_METHODS.map((signInMethod) => {
          const onlyOneLeft = activeSignInMethods.length === 1;
          const isEnabled = activeSignInMethods.includes(signInMethod.id);

          return (
            <div
              key={signInMethod.id}
              style={{ margin: "20px 0", display: "block " }}
            >
              {signInMethod.id === "password" ? (
                <DefaultLoginToggle
                  onlyOneLeft={onlyOneLeft}
                  isEnabled={isEnabled}
                  signInMethod={signInMethod}
                  onLink={this.onDefaultLoginLink}
                  onUnlink={this.onUnlink}
                />
              ) : (
                <SocialLoginToggle
                  onlyOneLeft={onlyOneLeft}
                  isEnabled={isEnabled}
                  signInMethod={signInMethod}
                  onLink={this.onSocialLoginLink}
                  onUnlink={this.onUnlink}
                />
              )}
            </div>
          );
        })}

        {error && error.message}
      </div>
    );
  }
}

const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) =>
  isEnabled ? (
    <Button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
    >
      {signInMethod.id === "google.com" ? <GoogleOutlined /> : ""}
      Deactivate {signInMethod.id}
    </Button>
  ) : (
    <Button type="button" onClick={() => onLink(signInMethod.provider)}>
      Link {signInMethod.id}
    </Button>
  );

class DefaultLoginToggle extends Component {
  constructor(props) {
    super(props);

    this.state = { passwordOne: "", passwordTwo: "" };
  }

  onSubmit = (values) => {
    // event.preventDefault();

    this.props.onLink(values.passwordOne);
    this.setState({ passwordOne: "", passwordTwo: "" });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { onlyOneLeft, isEnabled, signInMethod, onUnlink } = this.props;

    const { passwordOne, passwordTwo } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return isEnabled ? (
      <Button
        htmlType="button"
        onClick={() => onUnlink(signInMethod.id)}
        disabled={onlyOneLeft}
      >
        {signInMethod.id === "password" ? <KeyOutlined /> : ""} Deactivate{" "}
        {signInMethod.id}
      </Button>
    ) : (
      <Form layout="inline" onFinish={this.onSubmit}>
        <Form.Item name="passwordOne">
          <Input
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="New Password"
          />
        </Form.Item>
        <Form.Item name="passwordTwo">
          <Input
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirm New Password"
          />
        </Form.Item>
        <Form.Item>
          <Button disabled={isInvalid} htmlType="submit">
            Link {signInMethod.id}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const LoginManagement = withFirebase(LoginManagementBase);

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AccountPage);
