import React, { Component } from "react";

import { withFirebase } from "../Firebase";
import { Button, Form, Input, Alert } from "antd";
import { notify } from "../Utils";
const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        notify("success", "Success", "Changes saved");
      })
      .catch((error) => {
        this.setState({ error });
      });

    // event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return (
      <>
        <h1>Change password:</h1>
        <Form layout="inline" onFinish={this.onSubmit}>
          {error && (
            <>
              <Form.Item style={{ maxWidth: "500px" }}>
                <Alert
                  message={error.message}
                  type="info"
                  style={{ margin: "0px 20px;" }}
                  showIcon
                />
              </Form.Item>
              <br />
            </>
          )}
          <Form.Item>
            <Input
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="New Password"
            />
          </Form.Item>
          <Form.Item>
            <Input
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm New Password"
            />
          </Form.Item>
          <Form.Item>
            <Button disabled={isInvalid} htmlType="submit">
              Reset My Password
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default withFirebase(PasswordChangeForm);
