import React, { useState, useEffect } from "react";
import { Table, Alert } from "antd";
import { CloudDownloadOutlined, LoadingOutlined } from "@ant-design/icons";

const Invoices = (props) => {
  const [invoices, setInvoices] = useState(null);
  const [error, setError] = useState(null);

  const fetchInvoices = () => {
    try {
      const funTrans = props.firebase.func.httpsCallable("stripeGetInvoices");

      funTrans()
        .then((res) => {
          // setInvoices(res.data.data.map(i => {id:i.id, amount:i.amount_due, }));
          const invos = res.data.data.map((i) => {
            return {
              number: i.number,
              key: i.id,
              amount: i.amount_due,
              period_start: i.period_start,
              period_end: i.period_end,
              status: i.status,
              download: i.invoice_pdf,
            };
          });
          setInvoices(invos);
        })
        .catch((error) => {
          setError("Could not fetch invoices");
        });
    } catch (err) {
      alert(err);
    }
    return () => {};
  };

  useEffect(() => {
    const unsubscribe = fetchInvoices();
    return () => unsubscribe();
  }, []);

  const columns = [
    { title: "Invoice #", dataIndex: "number", key: "number" },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amt) => <span>&euro; {parseFloat(amt) / 100}</span>,
    },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Download",
      dataIndex: "download",
      key: "download",
      render: (link) => (
        <a href={link}>
          <CloudDownloadOutlined style={{ fontSize: "20px" }} />
        </a>
      ),
    },
  ];

  return (
    <div>
      {invoices ? (
        <Table
          dataSource={invoices}
          columns={columns}
          size="small"
          bordered={false}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
      ) : (
        <>
          {error ? (
            <Alert message={error} type="error" />
          ) : (
            <LoadingOutlined style={{ fontSize: 16 }} spin />
          )}
        </>
      )}
    </div>
  );
};

export default Invoices;
