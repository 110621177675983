import React from "react";
import { SplitPanel } from "../Dashboard";
import {
  withEmailVerification,
  withAuthorization,
  AuthUserContext,
  withAuthentication,
} from "../Session";
import { compose } from "recompose";

import BillingProfile from "./BillingProfile";
import { Redirect } from "react-router-dom";

function Billing(props) {
  return (
    <SplitPanel>
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div>
            {authUser.subscription ? (
              <BillingProfile authUser={authUser} firebase={props.firebase} />
            ) : (
              <Redirect to="/plans" />
            )}
          </div>
        )}
      </AuthUserContext.Consumer>
    </SplitPanel>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthentication,
  withAuthorization(condition)
)(Billing);
