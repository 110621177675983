import React, { useState, useEffect } from "react";
import StripeBadge from "./StripeBadge";
import { LoadingOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import {
  Card,
  Col,
  Row,
  Alert,
  Checkbox,
  Skeleton,
  Button,
  Modal,
  Form,
} from "antd";
import { Tag, Input, Tabs, Select } from "antd";
import countries from "../../constants/countries";
import { withRouter } from "react-router-dom";
import taxes from "../../constants/taxes";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import plans from "../../constants/plans";

const { TabPane } = Tabs;

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  // Form Details
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [invoice, setInvoice] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [authUserPlan, setAuthUserPlan] = useState(null);
  const [taxOrigin, setTaxOrigin] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    return props.firebase.user(props.authUser.uid).onSnapshot((snapshot) => {
      const authUser = snapshot.data();
      setAuthUserPlan(
        authUser.subscription ? authUser.subscription.plan.id : null
      );
      setReady(true);
    });
  }, []);

  const handleChoosePlan = (plan_id) => (e) => {
    setCurrentPlan(plans.filter((p) => p.id === plan_id).pop());
  };

  const handleCredit = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setCustomerData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onInvoiceCheckboxChange = (e) => {
    setInvoice(e.target.checked);
  };

  const handleCountrySelect = (country) => {
    setTaxOrigin(taxes(country));
    setCustomerData((prev) => {
      return {
        ...prev,
        country,
        taxType: taxes(country),
      };
    });
  };

  function RenderButton(props) {
    const { plan } = props;

    return ready ? (
      authUserPlan ? (
        authUserPlan === plan.id ? (
          <Button type="dashed" disabled>
            Your current plan
          </Button>
        ) : parseInt(authUserPlan.match(/\d+/)[0]) >
          parseInt(plan.id.match(/\d+/)[0]) ? (
          <Button type="dashed" disabled>
            Unavailable plan
          </Button>
        ) : (
          <Button onClick={handleChoosePlan(plan.id)}>Select</Button>
        )
      ) : (
        <Button onClick={handleChoosePlan(plan.id)}>Select</Button>
      )
    ) : (
      <LoadingOutlined style={{ marginLft: "10px", fontSize: 16 }} spin />
    );
  }

  const handleSubmit = async (event) => {
    if (!customerData.country) {
      setError("Select your country");
      return;
    }
    setLoading(true);
    const card = elements.getElement(CardElement);
    const { source, error } = await stripe.createSource(card);
    if (error) {
      setError(error.message);
    } else {
      const createSub = props.firebase.func.httpsCallable(
        "stripeCreateSubscription"
      );
      const data = {
        source: source,
        is_upgrade: authUserPlan ? true : false,
        plan: currentPlan.id,
        ...customerData,
      };
      try {
        await createSub(data);
        setError(null);
        Modal.success({
          title: "Success!",
          content: <div>Your subscription has been upgraded. </div>,
          onOk() {
            props.history.push("/monitoring/products/add");
          },
        });
      } catch (err) {
        setError(err.message);
      }
    }

    setLoading(false);
  };
  return (
    <>
      {plans ? (
        <Form onFinish={handleSubmit}>
          <Tabs type="card">
            <TabPane tab="Basic Plans" key="1">
              <Row>
                {plans.slice(0, 4).map((plan) => (
                  <Col
                    xxl={4}
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={24}
                    key={plan.id}
                  >
                    <Card
                      key={plan.id}
                      style={{ minWidth: "200px" }}
                      title={<Tag color={plan.color}>{plan.name}</Tag>}
                      bordered={false}
                    >
                      <p>&euro; {plan.price} / month</p>
                      <p>Monitor up to {plan.asins} asins</p>
                      <p>E-mail Alerts</p>
                      <p>{plan.marketplaces}</p>

                      {plan.extras
                        ? plan.extras.map((feature) => (
                            <p key={`${plan.id}_${feature}`}>
                              <Tag color="geekblue">{feature}</Tag>
                            </p>
                          ))
                        : ""}

                      <RenderButton plan={plan} />
                    </Card>
                  </Col>
                ))}
              </Row>
            </TabPane>
            <TabPane tab="Pro & Enterprise Plans" key="2">
              <Row>
                {plans.slice(4, 8).map((plan) => (
                  <Col
                    xxl={4}
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={24}
                    key={plan.id}
                  >
                    <Card
                      title={<Tag color={plan.color}>{plan.name}</Tag>}
                      bordered={false}
                    >
                      <p>&euro; {plan.price} / month</p>
                      <p>Monitor up to {plan.asins} asins</p>
                      <p>E-mail Alerts</p>
                      <p>{plan.marketplaces}</p>

                      {plan.extras
                        ? plan.extras.map((feature) => (
                            <p key={`${plan.id}_${feature}`}>
                              <Tag color="geekblue">{feature}</Tag>
                            </p>
                          ))
                        : ""}

                      <RenderButton plan={plan} />
                    </Card>
                  </Col>
                ))}
              </Row>
            </TabPane>
          </Tabs>

          {currentPlan && (
            <>
              <Row>
                <Col xxl={4} xl={6} lg={6} md={24}>
                  <Card title="Selected Plan" bordered={false}>
                    {currentPlan ? (
                      <>
                        <Tag
                          color={currentPlan.color}
                          style={{ marginDown: "10px" }}
                        >
                          {currentPlan.name} - &euro; {currentPlan.price} /
                          month
                        </Tag>

                        <p style={{ marginTop: "20px" }}>
                          {authUserPlan ? (
                            <>
                              Your plan will be upgraded immediately. Unused
                              time in your current plan will be prorated. <br />
                              You can upgrade or cancel your subscription
                              anytime. No contracts, no hidden charges.
                            </>
                          ) : (
                            <>
                              Start your free 7 day trial now.<br></br>
                              You will be charged{" "}
                              <strong>&euro; {currentPlan.price}</strong> on the{" "}
                              <Moment add={{ days: 7 }} format="Do">
                                {new Date()}
                              </Moment>{" "}
                              day of the month. <br />
                              You can upgrade or cancel your subscription
                              anytime. No contracts, no hidden charges.
                            </>
                          )}
                        </p>

                        <StripeBadge />

                        <p style={{ marginTop: "20px" }}>
                          Your data is securely processed by Stripe
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </Card>
                </Col>
                <Col xxl={12} xl={18} lg={18} md={24}>
                  <Card title="Summary" bordered={false}>
                    <Row gutter={[10, 10]}>
                      <Col xl={16} lg={24} md={24} sm={24} xs={24}>
                        <CardElement
                          stripe={stripe}
                          elements={elements}
                          onChange={handleCredit}
                          className="ant-input"
                          required
                          options={{
                            style: {
                              base: {
                                fontSize: "14px",
                                letterSpacing: "0.025em",
                                lineHeight: 1.5,

                                color: "#424770",
                                "::placeholder": {
                                  color: "#bfbfbf",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                        />
                      </Col>
                      <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                        <Select
                          showSearch
                          name="country"
                          style={{ width: "100%" }}
                          placeholder="Country"
                          onSelect={handleCountrySelect}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {countries.map((country) => (
                            <Select.Option value={country[1]} key={country[1]}>
                              {country[0]}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>

                      <Col xl={24}>
                        {error && <Alert message={error} type="error" />}
                      </Col>
                    </Row>
                    <Row gutter={[10, 10]}>
                      <Col lg={24}>
                        <Checkbox onChange={onInvoiceCheckboxChange}>
                          I need an invoice
                        </Checkbox>
                      </Col>
                    </Row>
                    <Row gutter={[10, 10]}>
                      <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <Input
                          placeholder="Company Name"
                          name="name"
                          onChange={handleChange}
                          type="text"
                          disabled={!invoice}
                          required={invoice}
                        />
                      </Col>
                      <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <Input
                          name="taxId"
                          addonBefore={taxOrigin ? taxOrigin : ""}
                          placeholder="Tax ID"
                          disabled={!invoice}
                          onChange={handleChange}
                          type="text"
                        />
                      </Col>
                    </Row>
                    <Row gutter={[10, 10]}>
                      <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <Input
                          placeholder="City"
                          name="city"
                          type="text"
                          disabled={!invoice}
                          required={invoice}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <Input
                          placeholder="Address"
                          name="address"
                          type="text"
                          disabled={!invoice}
                          onChange={handleChange}
                          required={invoice}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[10, 10]}>
                      <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <Input
                          placeholder="ZIP or postal code"
                          name="postal_code"
                          type="text"
                          disabled={!invoice}
                          required={invoice}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <Input
                          name="state"
                          placeholder="State, county, province, or region"
                          type="text"
                          onChange={handleChange}
                          disabled={!invoice}
                        />
                      </Col>
                    </Row>

                    <Row gutter={[10, 10]} justify="start">
                      <Col lg={4}>
                        <Button
                          htmlType="submit"
                          type="primary"
                          disabled={!stripe || loading}
                          style={{ margin: "20px 0" }}
                        >
                          {authUserPlan ? (
                            <>Upgrade Now</>
                          ) : (
                            <>Start your 7 day trial</>
                          )}
                          {loading ? (
                            <LoadingOutlined
                              style={{ marginLft: "10px", fontSize: 16 }}
                              spin
                            />
                          ) : (
                            ""
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Form>
      ) : (
        <Skeleton active />
      )}
    </>
  );
};

export default withRouter(CheckoutForm);
