import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyB70CK5K1cgRCRvk-DHT_Monw-KlTUt8UY",
  authDomain: "datanamo-v1.firebaseapp.com",
  databaseURL: "https://datanamo-v1.firebaseio.com",
  projectId: "datanamo-v1",
  storageBucket: "datanamo-v1.appspot.com",
  messagingSenderId: "825642386782",
  appId: "1:825642386782:web:00547c71122cada2313bcf",
  measurementId: "G-TY3LBF3H3L",
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.auth = app.auth();
    this.db = app.firestore();
    //
    // this.db.settings({
    //   host: "localhost:8080",
    //   ssl: false,
    // });

    this.func = app.functions();
    // this.func.useFunctionsEmulator("http://localhost:5001");

    this.googleProvider = new app.auth.GoogleAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
        ? process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
        : "https://app.datanamo.com",
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            // if (!dbUser) {
            //   fallback();
            // }

            // default empty roles
            // if (!dbUser.roles) {
            //   dbUser.roles = {};
            // }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = (uid) => this.db.doc(`users/${uid}`);

  users = () => this.db.collection("users");
}

export default Firebase;
