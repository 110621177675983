import React from "react";
import { Dropdown } from "antd";
import { Layout, Menu, Row, Breadcrumb } from "antd";

import {
  UserOutlined,
  DashboardOutlined,
  DownOutlined,
  AmazonOutlined,
  ReadOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";

import { Link, withRouter } from "react-router-dom";
import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const menu = (props) => (
  <Menu>
    <Menu.Item key="x0">
      <Link to="/account">Account</Link>
    </Menu.Item>
    <Menu.Item key="x1">
      <Link to="/billing">Billing</Link>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="x3">
      <span onClick={() => props.firebase.doSignOut()}>Logout</span>
    </Menu.Item>
  </Menu>
);

function Breadcrumbs(props) {
  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    const pageName = url.split("/").pop();
    const pageBreadcrumb =
      pageName.charAt(0).toUpperCase() + pageName.substring(1);
    return (
      <Breadcrumb.Item key={url}>
        {index === pathSnippets.length - 1 ? (
          pageBreadcrumb
        ) : (
          <Link to={url}>{pageBreadcrumb}</Link>
        )}
      </Breadcrumb.Item>
    );
  });

  const breadcrumbAll = [
    <Breadcrumb.Item key="dashboard">
      <Link to="/">Dashboard</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return <Breadcrumb style={{ margin: "16px 0" }}>{breadcrumbAll}</Breadcrumb>;
}

class SplitPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: localStorage.getItem("sider")
        ? JSON.parse(localStorage.getItem("sider"))
        : false,
    };
  }

  onCollapse = (collapsed) => {
    localStorage.setItem("sider", collapsed);
    this.setState({ collapsed });
  };
  // componentDidMount() {
  //   this.setState({ collapsed: JSON.parse(localStorage.getItem("sider")) });
  // }

  render = () => {
    const { location } = this.props;
    let subMenus = {
      "/billing": "sub2",
      "/account": "sub2",
      "/monitoring": "sub1",
      "/monitoring/products": "sub1",
      "/monitoring/alerts": "sub1",
      "/monitoring/products/add": "sub1",
      "/monitoring/settings": "sub1",
    };

    let opened = subMenus[location.pathname];
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          // theme="dark"
          breakpoint="xl"
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
        >
          <Menu
            theme="dark"
            mode="inline"
            defaultOpenKeys={[opened]}
            // selectedKeys={[selected]}
          >
            <div
              style={{
                lineHeight: "64px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="logo"
            >
              <b
                style={{
                  margin: "0 auto",
                  display: this.state.collapsed ? "none" : "inline",
                }}
              >
                <img
                  alt="Datanamo"
                  src={require("../Utils/logo-light.svg")}
                  style={{
                    width: "100px",
                  }}
                />
              </b>
              <img
                alt="Datanamo"
                src="https://firebasestorage.googleapis.com/v0/b/datanamo-v1.appspot.com/o/logo-light.svg?alt=media&token=154166dc-2d5c-4f68-a262-2cdf391b604e"
                style={{
                  height: 30,
                  display: this.state.collapsed ? "block" : "none",
                }}
              />
            </div>
            <Menu.Item
              key="/"
              onClick={() => this.props.history.push("/")}
              style={{ marginTop: 0 }}
            >
              <span>
                <DashboardOutlined />
                <span>Dashboard</span>
              </span>
            </Menu.Item>
            <SubMenu
              key="sub1"
              title={
                <span>
                  <AmazonOutlined />
                  <span>Amazon Tools</span>
                </span>
              }
            >
              <Menu.Item
                key="/monitoring"
                onClick={() => this.props.history.push("/monitoring")}
              >
                Listing Monitoring
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub2"
              title={
                <span>
                  <UserOutlined />
                  <span>Account</span>
                </span>
              }
            >
              <Menu.Item
                key="/account"
                onClick={() => this.props.history.push("/account")}
              >
                Account Settings
              </Menu.Item>
              <Menu.Item
                key="/billing"
                onClick={() => this.props.history.push("/billing")}
              >
                Billing
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              key="/help"
              onClick={() => this.props.history.push("/help")}
            >
              <span>
                <SafetyCertificateOutlined />
                <span>Getting Started</span>
              </span>
            </Menu.Item>
            <Menu.Item
              key="/changelog"
              onClick={() => this.props.history.push("/changelog")}
            >
              <span>
                <ReadOutlined />
                <span>Changelog</span>
              </span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ padding: 0 }}>
            <AuthUserContext.Consumer>
              {(authUser) => (
                <Menu
                  theme="dark"
                  mode="horizontal"
                  style={{
                    lineHeight: "64px",
                    textAlign: "right",
                    paddingRight: "6vw",
                  }}
                >
                  <Dropdown
                    overlay={withFirebase(menu)}
                    style={{ float: "right" }}
                  >
                    <span style={{ cursor: "pointer" }}>
                      {authUser.email.split("@")[0]} <DownOutlined />
                    </span>
                  </Dropdown>
                </Menu>
              )}
            </AuthUserContext.Consumer>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Breadcrumbs location={location} />

            <Row style={{ marginTop: "2vh" }}>
              <div
                style={{
                  marginTop: "2vh auto",
                  width: "100%",
                  padding: 24,
                  background: "#fff",
                  minHeight: 360,
                }}
              >
                {this.props.children}
              </div>
            </Row>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            &copy; {new Date().getFullYear()} Datanamo
            <br />
            <small>
              {" "}
              Datanamo.com, nor its operators are affiliated with or funded by
              Amazon.com, Inc in any way.
            </small>
          </Footer>
        </Layout>
      </Layout>
    );
  };
}

export default withRouter(withFirebase(SplitPanel));
