import React from "react";
import { Layout, Col, Row } from "antd";

function SplitScreen(props) {
  return (
    <Layout style={{ height: "100vh" }}>
      <Row>
        <Col
          md={12}
          xs={24}
          sm={24}
          style={{
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Row style={{ maxWidth: "70%" }}>
            <Col span={24}>{props.children}</Col>
          </Row>
        </Col>

        <Col
          id="splitscreen"
          style={{
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
          md={12}
          xs={24}
          sm={24}
        >
          <Row style={{ maxWidth: "50%" }}>
            <Col span={24}>
              <span style={{ marginBottom: "3vh", display: "block" }}>
                <h1 style={{ color: "white" }}>
                  Scalable Product Listing Monitoring for Amazon Sellers
                </h1>
              </span>
              <span>
                <h3 style={{ color: "#c4c4c4" }}>Monitor your competition</h3>
                <h3 style={{ color: "#c4c4c4" }}>
                  Track changes to your Amazon product listings
                </h3>
                <h3 style={{ color: "#c4c4c4" }}>
                  Receive instant alerts on product data changes
                </h3>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
}

export default SplitScreen;
