import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  message,
  Skeleton,
  Tooltip,
  Tabs,
  Tag,
  Modal,
  List,
  Table,
  Statistic,
  Input,
  Avatar,
  Badge,
  Typography,
} from "antd";
import Moment from "react-moment";
import {
  AmazonOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import marketplaces from "../../constants/marketplaces";
import { alertTypes } from "../../constants/alerts";
import FlagIcon from "../FlagIcon";
import { withEmailVerification, withAuthorization } from "../Session";
import { renderAlertType } from "../Utils";
import _ from "lodash";

const { Search } = Input;
const { TabPane } = Tabs;
const { Title, Text } = Typography;

function LastChange(props) {
  const { change } = props;
  let content = null;

  if (!change) return <span>-</span>;
  if (change.before && change.after) {
    if (
      typeof change.before !== "object" &&
      String(change.before).length < 20 &&
      String(change.after).length < 20
    ) {
      content = (
        <div>
          {renderAlertType(change.alertType)}
          <br />
          {change.before} <ArrowRightOutlined /> {change.after}
          <br />
          <Text type="secondary" style={{ fontSize: "12px" }}>
            <Moment fromNow>{new Date(change.created)}</Moment>
          </Text>
        </div>
      );
    } else {
      content = (
        <div>
          <Link to="/monitoring/alerts">
            <span style={{ cursor: "pointer" }}>
              {renderAlertType(change.alertType)}
            </span>
            <br />
            <Text type="secondary" style={{ fontSize: "12px" }}>
              <Moment fromNow>{new Date(change.created)}</Moment>
            </Text>
          </Link>
        </div>
      );
    }
  }

  return content;
}

const LatestAlerts = (props) => {
  const [alerts, setAlerts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsub = getAlerts();
    return () => unsub();
  }, []);

  const getAlerts = () => {
    setLoading(true);

    return props.firebase.db
      .collection("alerts")
      .where("user", "==", props.authUser.uid)
      .where("productId", "==", props.product.id)
      .orderBy("created", "desc")
      .limit(10)
      .onSnapshot(handleSnapshot, function (err) {
        setError(err.message);
      });
  };
  function handleSnapshot(snapshot) {
    try {
      const alerts = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setAlerts(alerts);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }

  return (
    <>
      {error ? error : ""}
      {alerts ? (
        <List
          size="small"
          bordered
          dataSource={alerts}
          renderItem={(a) => (
            <List.Item>
              <Tag>
                {alertTypes.filter((t) => t.code === a.alertType)[0].name}
              </Tag>
              <Text type="secondary" style={{ fontSize: "12px" }}>
                <Moment fromNow>{new Date(a.created)}</Moment>
              </Text>{" "}
              {_.isObject(a.before) &&
              a.alertType === "product_bsr_sub_categories" ? (
                <List
                  size="small"
                  style={{ marginTop: "10px" }}
                  dataSource={a.before}
                  renderItem={(item) => (
                    <List.Item>
                      {item.category}: <Tag color="blue">{item.rank}</Tag>
                    </List.Item>
                  )}
                />
              ) : _.isObject(a.before) ? (
                <pre>{JSON.stringify(a.before, null, 2)}</pre>
              ) : (
                a.before
              )}{" "}
              <ArrowRightOutlined />{" "}
              {_.isObject(a.after) &&
              a.alertType === "product_bsr_sub_categories" ? (
                <List
                  size="small"
                  style={{ marginTop: "10px" }}
                  dataSource={a.after}
                  renderItem={(item) => (
                    <List.Item>
                      {item.category}: <Tag color="blue">{item.rank}</Tag>
                    </List.Item>
                  )}
                />
              ) : _.isObject(a.after) ? (
                <pre>{JSON.stringify(a.after, null, 2)}</pre>
              ) : (
                a.after
              )}
            </List.Item>
          )}
        />
      ) : (
        // alerts.forEach((a) => (
        //   <Row key={a.id}>
        //     <Col xxl={24} xl={24} md={12}>
        //       <div>
        //         <Moment fromNow>{a.created}</Moment>
        //         {a.alert_type} - {a.before}=> {a.after}
        //       </div>
        //     </Col>
        //   </Row>
        // ))
        <Skeleton active />
      )}
    </>
  );
};

function ProductCharts(props) {
  const [alerts, setAlerts] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bsrHistory, setBsrHistory] = useState([]);
  const [priceHistory, setPriceHistory] = useState([]);
  const [reviewsHistory, setReviewsHistory] = useState([]);
  const [ratingHistory, setRatingHistory] = useState([]);

  useEffect(() => {
    const unsub = getAlerts();
    return () => unsub();
  }, []);

  const getAlerts = () => {
    setLoading(true);

    return props.firebase.db
      .collection("alerts")
      .where("user", "==", props.authUser.uid)
      .where("productId", "==", props.product.id)
      .orderBy("created", "desc")
      .onSnapshot(handleSnapshot, function (err) {
        setError(err.message);
      });
  };

  function handleSnapshot(snapshot) {
    try {
      const alerts = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      setAlerts(alerts);
      setBsrHistory(
        alerts.filter((alert) => alert.alertType === "product_bsr_main")
      );
      setPriceHistory(
        alerts.filter((alert) => alert.alertType === "product_price")
      );
      setReviewsHistory(
        alerts.filter((alert) => alert.alertType === "product_reviews")
      );
      setRatingHistory(
        alerts.filter((alert) => alert.alertType === "product_rating")
      );
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }

  let offers = props.product.offers ? Object.values(props.product.offers) : [];
  let avgPrice =
    offers.map((o) => o.priceTotal).reduce((a, b) => a + b, 0) / offers.length;

  let fbmOffers = offers.filter((o) => o.delivery === "FBM").length;
  let fbaOffers = offers.filter((o) => o.delivery === "FBA").length;
  let amzOffers = offers.filter((o) => o.delivery === "AMZ").length;
  let primeOffers = offers.filter((o) => o.isPrime === true).length;

  return (
    <>
      <>
        <Row>
          <Statistic
            title="BSR"
            value={props.product.bsr_main ? props.product.bsr_main : "-"}
            style={{
              margin: "0 16px",
            }}
          />
          <Statistic
            title="Buybox Price"
            prefix=""
            value={props.product.price ? props.product.price : "-"}
            style={{
              margin: "0 16px",
            }}
          />
          <Statistic
            title="Avg Price"
            value={avgPrice ? avgPrice.toFixed(2) : "-"}
            style={{
              margin: "0 16px",
            }}
          />
          <Statistic
            title="FBM Offers"
            prefix=""
            value={
              fbmOffers
                ? `${fbmOffers} (${Math.round(
                    (fbmOffers / offers.length) * 100
                  )} %)`
                : "-"
            }
            style={{
              margin: "0 16px",
            }}
          />
          <Statistic
            title="FBA Offers"
            prefix=""
            value={
              fbaOffers
                ? `${fbaOffers} (${Math.round(
                    (fbaOffers / offers.length) * 100
                  )} %)`
                : "-"
            }
            style={{
              margin: "0 16px",
            }}
          />
          <Statistic
            title="AMZ Offers"
            prefix=""
            value={
              amzOffers
                ? `${amzOffers} (${Math.round(
                    (amzOffers / offers.length) * 100
                  )} %)`
                : "-"
            }
            style={{
              margin: "0 16px",
            }}
          />
          <Statistic
            title="Prime Offers"
            prefix=""
            value={
              primeOffers
                ? `${primeOffers} (${Math.round(
                    (primeOffers / offers.length) * 100
                  )} %)`
                : "-"
            }
            style={{
              margin: "0 16px",
            }}
          />
        </Row>
        {/* <Row>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <div>fd</div>
          </Col>
        </Row> */}
      </>
    </>
  );
}

function ProductList(props) {
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [currentUsage, setCurrentUsage] = useState(null);
  const [error, setError] = useState(null);
  const { authUser } = props;

  useEffect(() => {
    const unsubscribe = getProducts();
    return () => unsubscribe();
  }, []);

  async function handleSearch(value) {
    let searched = null;
    let term = value.trim().toLowerCase();
    setLoading(true);
    if (!value) {
      searched = await props.firebase.db
        .collection("products")
        .where("user", "==", authUser.uid)
        .get();
    } else {
      if (term.length === 10) {
        searched = await props.firebase.db
          .collection("products")
          .where("user", "==", authUser.uid)
          .where("asin", "==", value.trim())
          .get();

        if (searched.empty) {
          searched = await props.firebase.db
            .collection("products")
            .where("user", "==", authUser.uid)
            .where("searchTerms", "array-contains", term)
            .get();
        }
      } else {
        searched = await props.firebase.db
          .collection("products")
          .where("user", "==", authUser.uid)
          .where("searchTerms", "array-contains", term)
          .get();
      }
    }

    if (searched) {
      setProducts(
        searched.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        })
      );
      setCurrentUsage(searched.docs.length);
    }
  }

  function getProducts() {
    setLoading(true);

    return props.firebase.db
      .collection("products")
      .where("user", "==", authUser.uid)
      .orderBy("lastChange.created", "desc")
      .onSnapshot(handleSnapshot, function (err) {
        setError(err.message);
      });
  }

  function handleSnapshot(snapshot) {
    try {
      const products = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      setProducts(products);
      setCurrentUsage(products.length);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }

  async function removeProductsAfterConfirmation(ids) {
    const deleteProducts = props.firebase.func.httpsCallable(
      "firestoreDeleteProducts"
    );
    if (!ids) {
      message.info("No products selected");
      return;
    }

    message.info(`Removing products...`);

    try {
      await deleteProducts(ids);
      message.success("Products removed");
    } catch (err) {
      message.error("Could not remove the products");
    }
  }

  async function handleDelete(ids) {
    const toDel = ids;
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: `Remove ${ids.length} product${
        ids.length === 1 ? "" : "s"
      } from tracker`,
      content: (
        <div>
          <p>
            This will also remove all the alert data history for the selected
            products. Are you sure you want to proceed?
          </p>
        </div>
      ),
      okText: "Yes, remove selected products",
      cancelText: "Cancel",
      onOk() {
        return (async () => {
          await removeProductsAfterConfirmation(toDel);
        })();
      },
    });
  }

  const columns = [
    {
      title: "Main Image",
      dataIndex: "mainImage",
      fixed: "left",
      render: (url) =>
        url ? (
          // <Avatar shape="square" size={64} src={url} />
          <img
            src={url.replace("_AC_", "_AC_US90")}
            style={{ maxHeight: "60px" }}
          />
        ) : (
          <Avatar shape="square" size={64} icon={<AmazonOutlined />} />
        ),
    },

    {
      title: "Product",
      fixed: "left",
      width: "200px",
      filters: marketplaces.map((m) => ({ text: m.name, value: m.iso })),
      filtered: true,
      filterMultiple: false,

      // onFilter: (value, record) => record.marketplace.toUpperCase === value,
      onFilter: (value, record) => {
        if (value === record.marketplace) {
          return record;
        }
      },

      render: (d) => (
        <div style={{ display: "block", width: "200px", paddingRight: "2px" }}>
          {d.productTitle}
          <br />
          <span style={{ marginRight: "10px" }}>
            <Tooltip placement="right" title={d.marketplace}>
              <span>
                <FlagIcon code={d.marketplace.toLowerCase()} />
              </span>
            </Tooltip>
          </span>
          <span>
            <Text type="secondary">{d.asin.toUpperCase()}</Text>{" "}
            <a href={d.url} target="_blank" rel="noopener noreferrer">
              <Tooltip placement="right" title="Amazon Product Page">
                <span>
                  <AmazonOutlined />
                </span>
              </Tooltip>
            </a>
          </span>
        </div>
      ),
    },
    {
      fixed: "left",
      title: "Last Alert",
      render: (row) => (
        <div style={{ minWidth: "100" }}>
          <span className="thOnMobile">
            <Title level={4}>Latest Change: </Title>
          </span>
          <div>
            {row.lastChange ? <LastChange change={row.lastChange} /> : "-"}
          </div>
        </div>
      ),
    },
    {
      title: "Monitoring",
      dataIndex: "monitoring_status",

      render: (s) => (
        <div style={{ textAlign: "center" }}>
          <span className="thOnMobile">
            <Title level={4}> Status: </Title>
          </span>

          {s ? (
            s === "ok" ? (
              <Tooltip placement="right" title="Monitoring is active">
                <span>
                  <Badge status="success" />
                </span>
              </Tooltip>
            ) : (
              <Tooltip placement="right" title="Something went wrong">
                <span>
                  {/* <Badge status="error" /> */}
                  <Tag icon={<CloseCircleOutlined />} color="error">
                    error
                  </Tag>
                </span>
              </Tooltip>
            )
          ) : (
            <Tooltip placement="right" title="Processing">
              <span>
                {/* <Badge status="warning" /> */}
                <Tag icon={<SyncOutlined spin />} color="processing">
                  processing
                </Tag>
              </span>
            </Tooltip>
          )}
        </div>
      ),
    },

    {
      title: "Brand",
      dataIndex: "manufacturer",

      render: (d) => (
        <>
          <span className="thOnMobile">
            <Title level={4}>Brand: </Title>
          </span>
          <span>{d}</span>
        </>
      ),
    },
    {
      title: "Category",
      render: (d) => (
        <>
          <span className="thOnMobile">
            <Title level={4}>Category: </Title>
          </span>
          <span>
            {d.bsr_main_category
              ? d.bsr_main_category
              : d.categories && d.categories.length
              ? d.categories[0].name
              : ""}
          </span>
        </>
      ),
    },
    {
      title: "Buybox Owner",
      dataIndex: "buybox",
      render: (d) => (
        <>
          <span className="thOnMobile">
            <Title level={4}>Buybox: </Title>
          </span>
          {d ? <span>{d.seller}</span> : ""}
        </>
      ),
    },
    {
      title: "Fulfilment",
      dataIndex: "buybox",
      render: (d) => (
        <>
          <span className="thOnMobile">
            <Title level={4}>Listing Type: </Title>
          </span>
          <Tooltip
            placement="right"
            title={
              d
                ? d.type === "AMZ"
                  ? "Ships from and sold by Amazon"
                  : d.type === "FBA"
                  ? "Fulfilment by Amazon"
                  : "Fulfilled by Merchant"
                : "-"
            }
          >
            <span>{d && d.type ? <span>{d.type}</span> : ""}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (d) => (
        <>
          <span className="thOnMobile">
            <Title level={4}>Current Price: </Title>
          </span>
          <span>{d}</span>
        </>
      ),
    },

    {
      title: "Rating",
      dataIndex: "rating",
      render: (d) => (
        <>
          <span className="thOnMobile">
            <Title level={4}>Rating: </Title>
          </span>
          <span>{d}</span>
        </>
      ),
    },
    {
      title: "Reviews",
      dataIndex: "ratingCount",
      render: (d) => (
        <>
          <span className="thOnMobile">
            <Title level={4}>Reviews: </Title>
          </span>
          <span>{d}</span>
        </>
      ),
    },

    {
      title: "BSR",
      dataIndex: "bsr_main",

      render: (d) => (
        <>
          <span className="thOnMobile">
            <Title level={4}>BSR: </Title>
          </span>
          {d ? <span>{d}</span> : ""}
        </>
      ),
    },

    {
      title: "Offers",
      dataIndex: "offers",
      render: (d) => (
        <>
          <span className="thOnMobile">
            <Title level={4}># of sellers: </Title>
          </span>
          <span>{d && Object.keys(d).length ? Object.keys(d).length : 0}</span>
        </>
      ),
    },

    {
      title: "Sellers",
      dataIndex: "offers",

      render: (d) => (
        <>
          <span className="thOnMobile">
            <Title level={4}># of sellers: </Title>
          </span>
          <span>
            {d && Object.keys(d)
              ? [...new Set(Object.values(d).map((o) => o.seller))].length
              : 0}
          </span>
        </>
      ),
    },
    {
      title: "Questions",
      dataIndex: "answeredQuestions",

      render: (d) => (
        <>
          <span className="thOnMobile">
            <Title level={4}># of questions: </Title>
          </span>
          <span>{d ? d : 0}</span>
        </>
      ),
    },
    {
      title: "Badge",
      dataIndex: "badge",

      render: (d) => (
        <>
          <span className="thOnMobile">
            <Title level={4}>Badge: </Title>
          </span>
          <span>{d ? d : ""}</span>
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedProducts(selectedRowKeys);
    },

    getCheckboxProps: (record) => ({
      name: record.name,
    }),
    selections: [
      {
        key: "remove",
        text: "Remove selected",
        onSelect: (s) => {
          if (selectedProducts) handleDelete(selectedProducts);
        },
      },
    ],
  };

  return (
    <div style={{}}>
      {error && <Alert message={error} type="error" />}
      <Row>
        <Col xl={24} lg={24} md={24}>
          {products ? (
            <>
              <Search
                placeholder="Search"
                // loading={true}
                onSearch={(value) => handleSearch(value)}
                style={{ width: "100%", marginTop: 20, marginBottom: 5 }}
              />
              <Text
                type="secondary"
                style={{ marginLeft: "10px", fontSize: "12px" }}
              >
                Found {currentUsage}{" "}
                {currentUsage === 1 ? "product" : "products"}
              </Text>
              <Table
                scroll={{ x: 1800 }}
                size="small"
                pagination={{
                  pageSize: 10,
                  position: ["bottomRight", "topRight"],
                }}
                rowSelection={{
                  ...rowSelection,
                }}
                expandable={{
                  expandedRowRender: (record) => (
                    <>
                      <Tabs size="small" animated={false}>
                        <TabPane tab="Summary" key="1">
                          <ProductCharts
                            product={record}
                            authUser={authUser}
                            firebase={props.firebase}
                          />
                        </TabPane>
                        <TabPane tab="Latest Alerts" key="2">
                          <Text type="secondary">Latest 10 Alerts</Text>
                          <LatestAlerts
                            product={record}
                            authUser={authUser}
                            style={{ marginTop: "5px" }}
                            firebase={props.firebase}
                          />
                        </TabPane>
                        <TabPane tab="Raw Data" key="3">
                          <pre>{JSON.stringify(record, undefined, 2)}</pre>
                        </TabPane>
                      </Tabs>
                    </>
                  ),
                  rowExpandable: (record) => true,
                }}
                dataSource={products}
                rowKey="id"
                columns={columns}
              />
            </>
          ) : (
            <Skeleton active />
          )}
        </Col>
      </Row>
    </div>
  );
}
const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(ProductList);
