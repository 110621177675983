import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import SplitScreen, { notify } from "../Utils";

import { Form, Input, Button, Alert } from "antd";
import { MailOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const PasswordForgetPage = () => (
  <SplitScreen>
    <div>
      <img
        alt="Datanamo"
        src={require("../Utils/logo-dark.svg")}
        style={{
          width: "100px",
          marginBottom: "40px",
        }}
      />
      <PasswordForgetForm></PasswordForgetForm>
    </div>
  </SplitScreen>
);

const INITIAL_STATE = {
  email: "",
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        notify("success", "Success", "Check your email!");
      })
      .catch((error) => {
        this.setState({ error });
      });

    // event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <Form
        onFinish={this.onSubmit}
        className="login-form"
        style={{ width: "300px", margin: "0 auto" }}
      >
        <h1>Password Reset</h1>
        {error && (
          <Form.Item style={{ maxWidth: "300px" }}>
            <Alert
              message="Error"
              description={error.message}
              type="error"
              style={{ margin: "0px 20px;" }}
              showIcon
            />
          </Form.Item>
        )}
        <Form.Item style={{ maxWidth: "300px" }}>
          <Input
            name="email"
            value={this.state.email}
            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
        </Form.Item>
        <Button
          style={{ width: "300px", maxWidth: "300px" }}
          disabled={isInvalid}
          htmlType="submit"
          className="login-form-button"
        >
          Reset My Password
        </Button>

        <div style={{ marginTop: "20px" }}>
          <Link to={ROUTES.SIGN_IN}>
            <ArrowLeftOutlined style={{ color: "rgba(0,0,0,.25)" }} /> Sign In
          </Link>
        </div>
      </Form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
