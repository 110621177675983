const marketplaces = [
  { iso: "US", url: "https://amazon.com", name: "United States" },
  { iso: "DE", url: "https://amazon.de", name: "Germany" },
  { iso: "GB", url: "https://amazon.co.uk", name: "United Kingdom" },
  { iso: "FR", url: "https://amazon.fr", name: "France" },
  { iso: "IT", url: "https://amazon.it", name: "Italy" },
  { iso: "NL", url: "https://amazon.nl", name: "Netherlands" },
  { iso: "CA", url: "https://amazon.ca", name: "Canada" },
  { iso: "ES", url: "https://amazon.es", name: "Spain" },
  { iso: "AU", url: "https://amazon.com.au", name: "Australia" },
  { iso: "JP", url: "https://amazon.co.jp", name: "Japan" },
  { iso: "IN", url: "https://amazon.in", name: "India" },
  { iso: "MX", url: "https://amazon.com.mx", name: "Mexico" },
  { iso: "BR", url: "https://amazon.com.br", name: "Brazil" },
];

export default marketplaces;
