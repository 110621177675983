import React from "react";
import { SplitPanel } from "../Dashboard";
import { Typography, Row, Col, List } from "antd";
import { Link } from "react-router-dom";
const { Title } = Typography;

export default function Changelog() {
  const changes = [
    {
      date: "07-06-2020",
      content: "Additional notification emails",
      link: "/monitoring/settings",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/datanamo-v1.appspot.com/o/Screen%20Shot%202020-06-07%20at%2020.17.01.png?alt=media&token=6733deaf-7d04-4435-8716-251cb9ae629f",
    },

    {
      date: "04-06-2020",
      content: "Trend for Best Sellers Rank alerts",
      link: "/monitoring/alerts",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/datanamo-v1.appspot.com/o/Screen%20Shot%202020-06-04%20at%2002.42.41.png?alt=media&token=bcacd1ee-9a2a-424e-be1c-05a59e4b7842",
    },
    {
      date: "04-06-2020",
      content:
        "Filter by marketplace added in listing monitoring product table",
      link: "/monitoring",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/datanamo-v1.appspot.com/o/productFiltering.png?alt=media&token=c2e62638-ef0e-4891-b12f-b6f9769f0131",
    },
    {
      date: "02-06-2020",
      content: "Top pagination added in listing monitoring product table",
      link: "/monitoring",
    },
    {
      date: "01-06-2020",
      content: "Latest alerts tab added in listing monitoring product table",
      link: "/monitoring",
    },
    {
      date: "26-05-2020",
      content: "Listing offers data fixed",
    },
    {
      date: "19-05-2020",
      content: "Alert searching and filtering",
      link: "/monitoring/alerts",
    },
  ];

  return (
    <SplitPanel>
      <Row gutter={[10, 10]}>
        <Col xl={16} lg={24} md={24} sm={24} xs={24}>
          <Title level={3}>Changelog</Title>
          <p>
            Implemented during beta tests. We would like to thank all the users
            and beta testers!
          </p>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col xl={16} lg={24} md={24} sm={24} xs={24}>
          <List
            size="small"
            bordered
            dataSource={changes}
            renderItem={(item) => (
              <List.Item>
                {item.date} - {item.content}.{" "}
                {item.link ? <Link to={item.link}>View</Link> : ""}
                {item.photo ? (
                  <img
                    src={item.photo}
                    alt="filter by marketplace feature"
                    style={{ maxWidth: "700px", width: "100%" }}
                  />
                ) : (
                  ""
                )}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </SplitPanel>
  );
}
