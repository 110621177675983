import React, { useState, useEffect } from "react";
import { Timeline } from "antd";

import {
  Row,
  Col,
  Alert,
  Skeleton,
  List,
  BackTop,
  Tooltip,
  Button,
  Card,
  Table,
  Tag,
  Select,
  Result,
  Typography,
  Input,
  Divider,
} from "antd";
import {
  RightOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  FallOutlined,
  RiseOutlined,
  ArrowRightOutlined,
  AmazonOutlined,
} from "@ant-design/icons";
import Moment from "react-moment";
import { compose } from "recompose";

import FlagIcon from "../FlagIcon";
import { withEmailVerification, withAuthorization } from "../Session";
import marketplaces from "../../constants/marketplaces";
import { alertTypes } from "../../constants/alerts";
const { Text } = Typography;
const { Search } = Input;

function AlertItem(props) {
  const { alert } = props;
  let content = null;
  let alertUrl = marketplaces.filter((m) => alert.marketplace === m.iso)[0].url;

  if (alert.alertType === "product_buybox") {
    content = (
      <Row>
        <Col xxl={8} xl={8} mg={12} sm={24}>
          <Text>
            Seller:{" "}
            <strong>{alert.before.seller ? alert.before.seller : "-"}</strong>
          </Text>
          <br />
          <Text type="secondary">
            Fulfilment: {alert.before.type ? alert.before.type : "-"}
          </Text>
          <br />
          {alert.before.sellerProfile && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={alert.before.sellerProfile}
            >
              View Seller Profile
            </a>
          )}
        </Col>
        <Col xxl={4} xl={4} mg={12} sm={24} type="flex" align="center">
          <ArrowRightOutlined />
        </Col>
        <Col xxl={8} xl={8} mg={12} sm={24}>
          <Text>
            Seller:{" "}
            <strong>{alert.after.seller ? alert.after.seller : "-"}</strong>
          </Text>
          <br />
          <Text type="secondary">
            Fulfilment: {alert.after.type ? alert.after.type : "-"}
          </Text>
          <br />
          {alert.after.sellerProfile && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={alert.after.sellerProfile}
            >
              View Seller Profile
            </a>
          )}
        </Col>
      </Row>
    );
  } else if (alert.alertType === "product_bullet_points") {
    content = (
      <Row>
        <Col xxl={8} xl={8} mg={12} sm={24}>
          <ul>
            {alert.before.map((b) => (
              <li key={`before_${b}`}>{b}</li>
            ))}
          </ul>
        </Col>
        <Col xxl={4} xl={4} mg={12} sm={24} type="flex" align="center">
          <ArrowRightOutlined />
        </Col>
        <Col xxl={8} xl={8} mg={12} sm={24}>
          <ul>
            <Text strong>
              {alert.after.map((b) => (
                <li key={`after_${b}`}>{b}</li>
              ))}
            </Text>
          </ul>
        </Col>
      </Row>
    );
  } else if (alert.alertType === "product_bsr_sub_categories") {
    content = (
      <Row>
        <Col xxl={8} xl={8} mg={12} sm={24}>
          <List
            size="small"
            bordered
            dataSource={alert.before}
            renderItem={(item) => (
              <List.Item>
                {item.category}: <Tag color="blue">{item.rank}</Tag>
              </List.Item>
            )}
          />
        </Col>
        <Col xxl={4} xl={4} mg={12} sm={24} type="flex" align="center">
          <ArrowRightOutlined />
        </Col>
        <Col xxl={8} xl={8} mg={12} sm={24}>
          <List
            size="small"
            bordered
            dataSource={alert.after}
            renderItem={(item) => (
              <List.Item>
                {item.category}: <Tag color="blue">{item.rank}</Tag>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    );
  } else if (alert.alertType === "product_bsr_main") {
    let percentageDiff = (
      (Math.abs(parseInt(alert.after) - parseInt(alert.before)) /
        parseInt(alert.before)) *
      100
    ).toFixed(2);
    content = (
      <>
        <Row>
          <Col xxl={8} xl={8} mg={12} sm={24}>
            <span>
              {alert.meta && alert.meta.category
                ? `${alert.meta.category}: `
                : ""}
              <Tag color="blue">{alert.before}</Tag>
            </span>
            {/* <pre>{JSON.stringify(alert, undefined, 2)}</pre> */}
          </Col>
          <Col xxl={4} xl={4} mg={12} sm={24} type="flex" align="center">
            <ArrowRightOutlined />
          </Col>
          <Col xxl={8} xl={8} mg={12} sm={24}>
            <span>
              {alert.meta && alert.meta.category
                ? `${alert.meta.category}: `
                : ""}
              <Tag color="blue">{alert.after}</Tag>
            </span>

            {/* <pre>{JSON.stringify(alert, undefined, 2)}</pre> */}
          </Col>
        </Row>
        <Row>
          {alert.before && alert.after ? (
            alert.before > alert.after ? (
              <Tooltip
                placement="right"
                title={`Best Sellers Rank has improved by ${percentageDiff} %`}
              >
                <span>
                  Trend: <RiseOutlined style={{ color: "#52c41a" }} />{" "}
                  <Text type="secondary" style={{ fontSize: "12px" }}>
                    {percentageDiff} %
                  </Text>
                </span>
              </Tooltip>
            ) : (
              <Tooltip
                placement="right"
                title={`Best Sellers Rank has declined by ${percentageDiff} %`}
              >
                <span>
                  Trend: <FallOutlined style={{ color: "#cf1322" }} />{" "}
                  <Text type="secondary" style={{ fontSize: "12px" }}>
                    {percentageDiff} %
                  </Text>
                </span>
              </Tooltip>
            )
          ) : (
            ""
          )}
        </Row>
      </>
    );
  } else if (alert.alertType === "product_main_image") {
    content = (
      <Row>
        <Col xxl={8} xl={8} mg={12} sm={24}>
          <img
            src={alert.before.replace("_AC_", "_AC_US90")}
            style={{ maxHeight: "160px" }}
            alt={`${alert.asin}_main_image`}
          />
        </Col>
        <Col xxl={4} xl={4} mg={12} sm={24} type="flex" align="center">
          <ArrowRightOutlined />
        </Col>
        <Col xxl={8} xl={8} mg={12} sm={24}>
          <img
            src={alert.after.replace("_AC_", "_AC_US90")}
            style={{ maxHeight: "160px" }}
            alt={`${alert.asin}_main_image`}
          />
        </Col>
      </Row>
    );
  } else if (alert.alertType === "product_sellers") {
    content = (
      <Row>
        <Col xxl={8} xl={8} mg={12} sm={24}>
          <span># of Offers: {alert.before.offers}</span>
          <br />
          <span># of Sellers: {alert.before.sellers}</span>
          <br />
        </Col>
        <Col xxl={4} xl={4} mg={12} sm={24} type="flex" align="center">
          <ArrowRightOutlined />
        </Col>
        <Col xxl={8} xl={8} mg={12} sm={24}>
          <span># of Offers: {alert.after.offers}</span>
          <br />
          <span># of Sellers: {alert.after.sellers}</span>
          <br />
          <span>New Sellers: </span>
          <br />
          <List
            size="small"
            bordered
            dataSource={alert.after.new_sellers}
            renderItem={(item) => (
              <List.Item>
                Seller: <strong>{item.seller}</strong>
                <br />
                <span>Price: {item.priceTotal}</span>
                <br />
                <span>Product Price: {item.price}</span>
                <br />
                <span>
                  Shipping: {item.shippingCost} ( {item.delivery} )
                </span>
                <br />
                Prime: <strong>{item.isPrime ? "yes" : "no"}</strong>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    );
  } else {
    content = (
      <Row>
        <Col xxl={8} xl={8} mg={12} sm={24}>
          {typeof alert.before === "object" ? (
            <pre>{JSON.stringify(alert.before, undefined, 2)}</pre>
          ) : alert.before && String(alert.before).length < 30 ? (
            <Tag color="blue">{alert.before}</Tag>
          ) : (
            <Text type="secondary">{alert.before}</Text>
          )}
        </Col>
        <Col xxl={4} xl={4} mg={12} sm={24} type="flex" align="center">
          <ArrowRightOutlined />
        </Col>
        <Col xxl={8} xl={8} mg={12} sm={24}>
          {typeof alert.after === "object" ? (
            <pre>{JSON.stringify(alert.after, undefined, 2)}</pre>
          ) : alert.after && String(alert.after).length < 30 ? (
            <Tag color="blue">{alert.after}</Tag>
          ) : (
            <Text type="secondary">{alert.after}</Text>
          )}
        </Col>
      </Row>
    );
  }

  return (
    <>
      <span style={{ marginRight: "10px" }}>
        <Tooltip placement="right" title={alert.marketplace}>
          <span>
            <FlagIcon code={alert.marketplace.toLowerCase()} />
          </span>
        </Tooltip>
      </span>
      <Tooltip placement="top" title={alert.productTitle}>
        <span>{alert.asin}</span>
      </Tooltip>

      <a
        href={alertUrl + `/dp/${alert.asin}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Tooltip placement="right" title="Amazon Product Page">
          <span>
            <AmazonOutlined />
          </span>
        </Tooltip>
      </a>
      <br />
      {alertTypes.filter((a) => a.code === alert.alertType)[0].name}
      <br />
      {content}
    </>
  );
}

function AlertList(props) {
  const [alerts, setAlerts] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [asin, setAsin] = useState(null);
  const [country, setCountry] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { authUser } = props;

  useEffect(() => {
    const unsubscribe = getAlerts();
    return () => unsubscribe();
  }, []);

  function getAlerts() {
    setLoading(true);

    return props.firebase.db
      .collection("alerts")
      .where("user", "==", authUser.uid)
      .orderBy("created", "desc")
      .limit(100)
      .onSnapshot(handleSnapshot, function (err) {
        setError(err.message);
      });
  }

  function handleSnapshot(snapshot) {
    try {
      const alerts = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      setAlerts(alerts);
    } catch (err) {
      // console.log(err);
      setError(err.message);
    }
    setLoading(false);
  }

  const handleCountrySelect = (country) => {
    setCountry(country);
  };
  function handleChangeAlertType(e) {
    setAlertType(e);
  }
  function handleChangeAsin(e) {
    setAsin(e.target.value);
  }

  async function handleSearch() {
    setIsSearching(true);
    let query = props.firebase.db
      .collection("alerts")
      .where("user", "==", authUser.uid);

    if (asin) {
      query = query.where("asin", "==", asin.trim());
    }
    if (alertType && alertType !== "all") {
      query = query.where("alertType", "==", alertType);
    }
    if (country && country !== "all") {
      query = query.where("marketplace", "==", country);
    }
    let searched;
    try {
      searched = await query.orderBy("created", "desc").limit(100).get();
    } catch (err) {
      searched = null;
      // console.log(err);
      setError(err);
    }
    if (searched) {
      setAlerts(
        searched.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        })
      );
    }
    setIsSearching(false);
  }

  return (
    <div style={{ marginTop: "3vh" }}>
      {/* {error && <Alert message={error} type="error" />} */}
      <BackTop />

      <Row gutter={[10]}>
        <Col
          xl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ textAlign: "left" }}
        >
          {alerts && <h3>Latest Alerts</h3>}
          {alerts ? (
            <>
              <Row>
                <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
                  <Input
                    placeholder="Search by ASIN"
                    onChange={handleChangeAsin}
                    style={{ width: "100%", marginTop: 0, marginBottom: 20 }}
                  />
                </Col>
                <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                  <Select
                    name="marketplace"
                    defaultValue="all"
                    style={{ width: "100%", marginTop: 0, marginBottom: 20 }}
                    placeholder="Filter by marketplace"
                    label="Marketplace"
                    onSelect={handleCountrySelect}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Select.Option value="all" key="all">
                      <span style={{ marginLeft: "5px" }}>
                        All marketplaces
                      </span>
                    </Select.Option>
                    {marketplaces.map((m) => (
                      <Select.Option value={m.iso} key={m.name}>
                        <FlagIcon code={m.iso.toLowerCase()} />{" "}
                        <span style={{ marginLeft: "5px" }}>
                          {m.name} - {m.url.replace("https://", "")}
                        </span>
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                  <Select
                    name="alertTypes"
                    defaultValue="all"
                    style={{
                      width: "100%",
                      marginTop: 0,
                      marginBottom: 20,
                      marginRight: "10px",
                    }}
                    placeholder="Filter by alert type"
                    label="Alert Type"
                    onSelect={handleChangeAlertType}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Select.Option value="all" key="all">
                      <span style={{ marginLeft: "5px" }}>All Alerts</span>
                    </Select.Option>
                    {alertTypes.map(
                      (m) =>
                        m.code !== "alerts_daily_summary" && (
                          <Select.Option value={m.code} key={m.code}>
                            <span style={{ marginLeft: "5px" }}>{m.name}</span>
                          </Select.Option>
                        )
                    )}
                  </Select>
                </Col>
                <Row>
                  <Col>
                    <Button
                      type="primary"
                      onClick={handleSearch}
                      loading={isSearching}
                      style={{ marginBottom: "20px" }}
                      icon={<SearchOutlined />}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Row>
              <Row>
                <Col xxl={20} xl={24} lg={24} md={24}>
                  <Timeline mode="left">
                    {!alerts.length && (
                      <Result
                        status="info"
                        icon={<InfoCircleOutlined />}
                        subTitle="No Alerts Found"
                      />
                    )}
                    {alerts.map((p) => (
                      <Timeline.Item
                        key={p.id}
                        position="left"
                        label={
                          <div>
                            <Tooltip
                              placement="left"
                              title={new Date(p.created).toISOString()}
                            >
                              <span>
                                <Moment fromNow>{p.created}</Moment>
                              </span>
                            </Tooltip>
                            <br />
                            <img
                              style={{
                                marginTop: 20,
                                maxHeight: "50px",
                              }}
                              src={
                                p.mainImage
                                  ? p.mainImage.replace("_AC_", "_AC_US40")
                                  : ""
                              }
                              alt={p.asin}
                            />
                          </div>
                        }
                      >
                        {/* <div key={p.id} style={{ marginBottom: 20 }}>
                          <pre>{JSON.stringify(p, null, 2)}</pre>
                        </div> */}
                        <AlertItem
                          alert={p}
                          key={p.id}
                          style={{ marginBottom: 20 }}
                        />
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </Col>
              </Row>
            </>
          ) : (
            <Skeleton active />
          )}
        </Col>
      </Row>
    </div>
  );
}

const condition = (authUser) => !!authUser;
export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AlertList);
