const taxes = country => {
  const enums = {
    AU: "au_abn",
    CA: "ca_bn",
    ES: "eu_vat",
    AT: "eu_vat",
    BE: "eu_vat",
    BG: "eu_vat",
    CY: "eu_vat",
    CZ: "eu_vat",
    DE: "eu_vat",
    DK: "eu_vat",
    EE: "eu_vat",
    FI: "eu_vat",
    GB: "eu_vat",
    GR: "eu_vat",
    HR: "eu_vat",
    HU: "eu_vat",
    IE: "eu_vat",
    IT: "eu_vat",
    LU: "eu_vat",
    LV: "eu_vat",
    MT: "eu_vat",
    NL: "eu_vat",
    PL: "eu_vat",
    PT: "eu_vat",
    RO: "eu_vat",
    SI: "eu_vat",
    SK: "eu_vat",
    SE: "eu_vat",
    HK: "hk_br",
    IN: "in_gst",
    JP: "jp_cn",
    KR: "kr_brn",
    LI: "li_uid",
    MX: "mx_rfc",
    MY: "my_itn",
    NO: "no_vat",
    NZ: "nz_gst",
    RU: "ru_inn",
    SG: "sg_uen",
    TH: "th_vat",
    TW: "tw_vat",
    US: "us_ein",
    ZA: "za_vat"
  };
  return enums[country];
};

export default taxes;
