import React from "react";
import { SplitPanel } from "../Dashboard";
import { withFirebase } from "../Firebase";
import {
  withEmailVerification,
  withAuthorization,
  AuthUserContext,
} from "../Session";
import { compose } from "recompose";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
const stripePromise = loadStripe("pk_live_dy5FUn0xgusTinERDALUnxBc00HOlIzYSQ");

function Plans(props) {
  return (
    <SplitPanel>
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div>
            <Elements stripe={stripePromise}>
              <CheckoutForm authUser={authUser} firebase={props.firebase} />
            </Elements>
          </div>
        )}
      </AuthUserContext.Consumer>
    </SplitPanel>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withFirebase,
  withAuthorization(condition)
)(Plans);
