import React from "react";
import { Empty, Layout, Button } from "antd";
import { compose } from "recompose";
import { ShopOutlined } from "@ant-design/icons";
import { withAuthorization, withEmailVerification } from "../Session";
import { Link } from "react-router-dom";
import SplitPanel from "./SplitPanel";
const { Content } = Layout;

function Dashboard() {
  return (
    <SplitPanel>
      <Content style={{ padding: "0 50px", minWidth: "80wh" }}>
        <Empty
          image={<ShopOutlined style={{ fontSize: "36px" }} />}
          imageStyle={{
            height: 40,
          }}
          description={
            <span>Track changes to your Amazon Product Listings</span>
          }
        >
          <Link to="/monitoring/products/add">
            <Button type="primary">Add Products</Button>
          </Link>
          <p>or</p>
          <Link to="/monitoring/products">
            <Button type="primary">View Tracked Products</Button>
          </Link>
        </Empty>
      </Content>
    </SplitPanel>
  );
}
const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(Dashboard);
