import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
  Select,
  Alert,
  Form,
  Progress,
  Input,
  Modal,
} from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import marketplaces from "../../constants/marketplaces";
import FlagIcon from "../FlagIcon";
import { withEmailVerification, withAuthorization } from "../Session";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { notify } from "../Utils";

const { TextArea } = Input;

const CurrentUsage = (props) => {
  const [authUser, setUserData] = useState(props.authUser);
  useEffect(() => {
    return props.firebase.user(props.authUser.uid).onSnapshot((snapshot) => {
      setUserData(snapshot.data());
    });
  }, []);

  return (
    <div style={{ marginBottom: "20px" }}>
      <h3>Usage</h3>

      <Progress
        type="circle"
        strokeColor={{
          "0%": "#1890ff",
          "100%": "#002766",
        }}
        percent={
          (parseInt(authUser.productsTracked ? authUser.productsTracked : 0) /
            (authUser.subscription
              ? parseInt(authUser.subscription.plan.id.match(/\d+/)[0])
              : 0)) *
          100
        }
        width={80}
        format={() => (
          <span style={{ color: "#002766" }}>
            <small>
              {`${authUser.productsTracked ? authUser.productsTracked : 0} / ${
                authUser.subscription
                  ? parseInt(authUser.subscription.plan.id.match(/\d+/)[0])
                  : 0
              }
                      `}
            </small>
          </span>
        )}
      />
    </div>
  );
};

function AddProducts(props) {
  const [marketplace, setMarketplace] = useState(null);
  const [asins, setAsins] = useState([]);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const { authUser } = props;

  function handleTagChange(tags) {
    setTags(tags);
  }

  function handleAsinChange(e) {
    const newAsins = e.target.value
      .replace(/,/g, " ")
      .replace(/\n/g, " ")
      .split(" ");

    const correctAsins = newAsins.map((a) => {
      const asn = a.toLowerCase();
      const reg = asn.includes("dp/") ? /dp\/\w{10}/ : /^\w{10}$/;
      const correct = asn.match(reg);
      if (correct && correct.length) {
        return correct[0].replace(/dp\//, "");
      }
    });
    const unique = [...new Set(correctAsins)];
    setAsins(unique.filter((a) => !!a));
  }
  async function handleSubmit(e) {
    setLoading(true);
    setIsAdding(true);
    if (!asins.length) {
      setError("Could not extract any correct ASINS");
      return;
    }
    const data = {
      marketplace,
      tags,
      asins,
    };
    const addProducts = props.firebase.func.httpsCallable(
      "firestoreAddProducts"
    );
    try {
      await addProducts(data);

      setMarketplace(null);
      setAsins([]);
      setTags([]);

      Modal.success({
        title: "Success!",
        content: <div>Products added to tracker</div>,
        onOk() {
          props.history.push("/monitoring");
        },
      });
    } catch (err) {
      setError(err.message);
      notify("error", "Error", err.message);
    }
    setLoading(false);
    setIsAdding(false);
  }
  function handleCountrySelect(c) {
    setMarketplace(c);
  }

  return (
    <div style={{ marginTop: "3vh" }}>
      <Form onFinish={handleSubmit}>
        <Row>
          <Col xl={12} lg={12} md={12}>
            <h3>Schedule product listing monitoring</h3>
            <Form.Item>
              <Select
                name="marketplace"
                placeholder="Select a marketplace"
                label="Marketplace"
                onSelect={handleCountrySelect}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {marketplaces.map((m) => (
                  <Select.Option value={m.iso} key={m.name}>
                    <FlagIcon code={m.iso.toLowerCase()} />{" "}
                    <span style={{ marginLeft: "5px" }}>
                      {m.name} - {m.url.replace("https://", "")}
                    </span>
                  </Select.Option>
                ))}
                <Select.Option value="TR" key={"Turkey"} disabled={true}>
                  <FlagIcon code="tr" />{" "}
                  <span style={{ marginLeft: "5px" }}>
                    Turkey - amazon.tr (<small>coming soon</small>)
                  </span>
                </Select.Option>
                <Select.Option value="AE" key={"UAE"} disabled={true}>
                  <FlagIcon code="ae" />{" "}
                  <span style={{ marginLeft: "5px" }}>
                    United Arab Emirates - amazon.ae (<small>coming soon</small>
                    )
                  </span>
                </Select.Option>
                <Select.Option value="SG" key={"SG"} disabled={true}>
                  <FlagIcon code="sg" />{" "}
                  <span style={{ marginLeft: "5px" }}>
                    Singapore - amazon.sg (<small>coming soon</small>)
                  </span>
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Select
                label="Tags"
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Add optional tags"
                onChange={handleTagChange}
              ></Select>
            </Form.Item>
            <Form.Item>
              <TextArea
                rows={10}
                placeholder="Paste asins or amazon links delimited by comma or a new line"
                onChange={handleAsinChange}
                disabled={!marketplace}
                // value={asins}
                autoSize={{ minRows: 10, maxRows: 20 }}
              />
            </Form.Item>
            {error && (
              <Alert
                message={error}
                type="error"
                style={{ marginBottom: "2vh" }}
              />
            )}
          </Col>

          <Col xl={12} lg={12} md={12}>
            <Form.Item style={{ margin: "0 3rem" }}>
              <CurrentUsage firebase={props.firebase} authUser={authUser} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Button
              style={{ marginBottom: "20px" }}
              type="primary"
              disabled={isAdding || !asins.length || !marketplace}
              htmlType="submit"
            >
              Add {asins ? asins.length : ""} Products
              {loading ? <LoadingOutlined style={{ fontSize: 16 }} spin /> : ""}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withFirebase,
  withAuthorization(condition)
)(AddProducts);
