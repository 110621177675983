exports.alertTypes = [
  {
    code: "product_bsr_main",
    name: "BSR",
    help: "Changes to best sellers rank in product's main category",
    type: "number",
    settings: ["boolean", "number"],
    has_slider: true,
  },
  {
    code: "product_bsr_sub_categories",
    name: "BSR in subcategories",
    help: "Changes to product's rank in its subcategories",
    type: "number",
    settings: ["boolean", "number"],
    has_slider: true,
  },
  {
    code: "product_sellers",
    name: "New Sellers / Listing Hijackers",
    help: "Alert when new sellers start selling monitored product",
    type: "object",
    settings: "object",
    has_slider: false,
  },
  {
    code: "product_price",
    name: "Product Price",
    help: "Changes to product's price",
    type: "number",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_rating",
    name: "Product Rating",
    help: "Alert when product gains or loses star rating",
    type: "number",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_reviews",
    name: "Product Reviews",
    help: "Alert when product gains or loses reviews",
    type: "number",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_main_category",
    name: "Product Category",
    help: "Alert when product changes main category",
    type: "string",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_title",
    name: "Product Title",
    help: "Changes to product's title",
    type: "string",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_brand",
    name: "Product Brand",
    help: "Changes to product's brand/manufacturer name",
    type: "string",
    settings: "boolean",
    has_slider: false,
  },

  {
    code: "product_questions",
    name: "Questions Answered",
    help: "Alert when product's 'answered quesions' number changes",
    type: "number",
    settings: "boolean",
    has_slider: false,
  },

  {
    code: "product_availability",
    name: "Product Availability",
    help: "Alert when product's availability changes",
    type: "string",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_buybox",
    name: "Product Buybox",
    help: "Alert when product's buybox changes its owner",
    type: "object",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_main_image",
    name: "Main Image",
    help: "Alert when product's main image changes",
    type: "string",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_image_count",
    name: "Image Count",
    help: "Alert when total number of images changes",
    type: "number",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_bullet_points",
    name: "Bullet Points",
    help: "Alert when listing bullet points change",
    type: "object",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_badge",
    name: "Badge",
    help: "Alert when product gains/loses Amazon badge",
    type: "string",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_description",
    name: "Product Description",
    help: "Alert when listing's main description changes",
    type: "string",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_dimensions",
    name: "Product Dimensions",
    help: "Alert when product's dimensions change",
    type: "string",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_review_most_helpful_positive",
    name: "Most Helpful Review - positive",
    help: "Alert when product's most helpful, positive review changes",
    type: "object",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_review_most_helpful_negative",
    name: "Most Helpful Review - negative",
    help: "Alert when product's most helpful, negative review changes",
    type: "object",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "product_shipping_weight",
    name: "Product Shipping Weight",
    help: "Alert when product's shipping weight changes",
    type: "string",
    settings: "boolean",
    has_slider: false,
  },
  {
    code: "alerts_daily_summary",
    name: "Daily Summary",
    help: "Send daily summary of all the changes",
    type: "boolean",
    settings: "boolean",
    has_slider: false,
  },

  {
    code: "product_map_violation",
    name: "Potential MAP violation",
    help:
      "[Minimum Advertised Price] Alert when one of the listing's offers for new product has a significantly lower price than the average",
    type: "object",
    settings: "object",
    has_slider: true,
  },
];
