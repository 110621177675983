import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";

import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";

import * as ROUTES from "../../constants/routes";

import { Form, Input, Button, Alert } from "antd";
import { LockOutlined, UserOutlined, GoogleOutlined } from "@ant-design/icons";
import SplitScreen, { notify } from "../Utils";

const SignInPage = () => (
  <SplitScreen>
    <div>
      {/* <p>Datanamo</p> */}
      <img
        alt="Datanamo"
        src={require("../Utils/logo-dark.svg")}
        style={{
          width: "100px",
          marginBottom: "40px",
        }}
      />
      <SignInForm>
        <SignInGoogle />
        <PasswordForgetLink />
        <SignUpLink />
      </SignInForm>
      <br />
    </div>
  </SplitScreen>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  loading: false,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  "auth/account-exists-with-different-credential";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;
    this.setState({ loading: true });

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.DASHBOARD);
      })
      .catch((error) => {
        this.setState({ error });
      });

    // event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <>
        <Form
          onFinish={this.onSubmit}
          className="login-form"
          style={{ width: "300px", margin: "0 auto" }}
        >
          <h1>Sign In</h1>
          {error && (
            <Form.Item style={{ maxWidth: "300px" }}>
              <Alert
                message="Error"
                description={error.message}
                type="error"
                style={{ margin: "0px 20px;" }}
                showIcon
              />
            </Form.Item>
          )}
          <Form.Item style={{ maxWidth: "300px" }}>
            <Input
              name="email"
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
          </Form.Item>
          <Form.Item style={{ maxWidth: "300px" }}>
            <Input
              name="password"
              value={password}
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Button
            style={{ width: "300px", maxWidth: "300px" }}
            type="submit"
            disabled={isInvalid}
            htmlType="submit"
            className="login-form-button"
          >
            Sign In
          </Button>
          {this.props.children}
        </Form>
      </>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null, loading: false };
  }

  onSubmit = (event) => {
    this.setState({ loading: true });
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
            roles: {},
          },
          { merge: true }
        );
      })
      .then(() => {
        this.setState({ error: null, loading: false });
        this.props.history.push(ROUTES.DASHBOARD);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        notify("warning", "Oops", error.message);

        this.setState({ error: error, loading: false });
      });

    // event.preventDefault();
  };

  render() {
    return (
      <Form.Item>
        <Button
          style={{ width: "300px", maxWidth: "300px", marginTop: "10px" }}
          onClick={this.onSubmit}
          type="danger"
        >
          <GoogleOutlined />
          Sign In with Google
        </Button>
      </Form.Item>
    );
  }
}
const SignInLink = () => (
  <p>
    Already have an account? <Link to={ROUTES.SIGN_IN}>Sign In</Link>
  </p>
);

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase);

export default SignInPage;

export { SignInForm, SignInGoogle, SignInLink };
