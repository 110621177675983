import SplitScreen from "./SplitScreen";
import Changelog from "./Changelog";
import { notification } from "antd";

export const notify = (type, title, desc) => {
  notification[type]({
    message: title,
    description: desc,
  });
};

function renderAlertType(code) {
  let titles = {
    product_bsr_main: "BSR in main category",
    product_main_category: "Main Category",
    product_title: "Product Title",
    product_brand: "Product Brand",
    product_rating: "Product Rating",
    product_reviews: "Reviews",
    product_questions: "Questions Answered",
    product_price: "Product Price",
    product_availability: "Availability",
    product_buybox: "Buybox",
    product_main_image: "Main Image",
    product_image_count: "Image Count",
    product_bullet_points: "Bullet Points",
    product_badge: "Badge",
    product_bsr_sub_categories: "BSR in sub-categories",
    product_description: "Product Description",
    product_dimensions: "Product Dimensions",
    product_review_most_helpful_positive: "Most Helpful Review - positive",
    product_review_most_helpful_negative: "Most Helpful Review - negative",
    product_item_weight: "Product Item Weight",
    product_size: "Product Size",
    product_shipping_weight: "Product Shipping Weight",
    alerts_daily_summary: "Daily Summary",
    product_sellers: "New Sellers / Listing Hijackers",
    product_map_violation: "Potential MAP policy violation",
  };
  if (code in titles) {
    return titles[code];
  } else {
    return code.replace("_", " ");
  }
}

export default SplitScreen;
export { renderAlertType, Changelog };
