import React from "react";
import { SplitPanel } from "../Dashboard";
import { Typography, Row, Col } from "antd";
import { Link } from "react-router-dom";
const { Title, Text } = Typography;

export default function Help() {
  return (
    <SplitPanel>
      <Row gutter={[10, 10]}>
        <Col xl={16} lg={24} md={24} sm={24} xs={24}>
          <Title level={3}>Getting Started</Title>
          <Text mark>
            Add Products (
            <small>
              <Link to="/monitoring/products/add">here</Link>
            </small>
            )
          </Text>

          <Text strong>
            <ol>
              <li>Choose your marketplace</li>
              <li>
                Enter tags (it will later help with reading and grouping
                different alerts)
              </li>
              <li>
                Paste asins or links, delimited by comma or a new line. Make
                sure that you paste correct listings for marketplace you've
                chosen in step 1.
              </li>
              <li>Click 'Add Products'</li>
            </ol>
          </Text>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/datanamo-v1.appspot.com/o/guide-add-products.png?alt=media&token=ea0605a7-1669-4499-8220-a9fae44a12f6"
            alt="Guide - Step 1 - Add Products"
            style={{ maxWidth: "600px", width: "100%" }}
          />
          <Row>
            <Col style={{ margin: "20px 0" }}>
              <Text mark>
                Adjust alert preferences (
                <small>
                  <Link to="/monitoring/settings">here</Link>
                </small>
                )
              </Text>

              <Text strong>
                <div>
                  You can choose what types of alerts we should send you an
                  email about
                </div>
              </Text>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/datanamo-v1.appspot.com/o/guide-adjust-alert-prefs.png?alt=media&token=3a1b604a-45f0-4d04-9868-e577f8d3daea"
                alt="Guide - Step 2 - Adjust Alert Preferences"
                style={{ maxWidth: "600px", width: "100%" }}
              />
            </Col>
          </Row>
          <div>
            <Text mark>
              Inspect your tracked products (
              <small>
                <Link to="/monitoring/products">here</Link>
              </small>
              )
            </Text>
          </div>
          <div>
            <Text mark>
              View all alerts(
              <small>
                <Link to="/monitoring/alerts">here</Link>
              </small>
              )
            </Text>
          </div>
        </Col>
      </Row>
    </SplitPanel>
  );
}
