import React, { useState, useEffect } from "react";

import { Col, Card, Row, Tag, Alert, Button, Popconfirm } from "antd";
import Moment from "react-moment";

import { AuthUserContext } from "../Session";
import { LoadingOutlined } from "@ant-design/icons";
import Invoices from "./Invoices";

import plans from "../../constants/plans";
import { Link } from "react-router-dom";

const BillingProfile = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState(props.authUser.subscription);
  const [upcoming, setUpcoming] = useState(null);

  const fetchInvoices = () => {
    const funUpInvoice = props.firebase.func.httpsCallable(
      "stripeGetUpcomingInvoice"
    );
    funUpInvoice()
      .then((res) => {
        setUpcoming(res.data);
      })
      .catch((err) => setError(`Could not fetch subscription details`));
    return () => {};
  };

  const fetchSubscription = () => {
    const funSub = props.firebase.func.httpsCallable("stripeGetSubscriptions");
    funSub()
      .then((res) => setSubscription(res.data))
      .catch((err) => setError(`Could not fetch subscription details`));

    return () => {};
  };

  useEffect(() => {
    const unsubscribe = fetchInvoices();
    return () => unsubscribe();
  }, [subscription.status]);

  useEffect(() => {
    const unsubscribe = fetchSubscription();
    return () => unsubscribe();
  }, [subscription.status]);

  async function handleDontCancel() {
    setLoading(true);
    try {
      const stopCanceling = props.firebase.func.httpsCallable(
        "stripeStopCancelSubscription"
      );
      const res = await stopCanceling({
        subId: subscription.id,
      });

      setSubscription(res.data);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }

  async function handleCancelSubscription() {
    setLoading(true);
    try {
      const cancel = props.firebase.func.httpsCallable(
        "stripeCancelSubscription"
      );
      const res = await cancel({
        subId: subscription.id,
      });

      setSubscription(res.data);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }

  return (
    <Row gutter={[10, 10]} style={{ marginTop: "10px" }}>
      <Col xl={12} md={24}>
        <Card title="Subscription Details" bordered={false}>
          {subscription ? (
            <>
              <div>Current Plan</div>

              <Tag
                style={{ fontSize: "25" }}
                color={
                  plans.filter((p) => p.id === subscription.plan.id)[0]
                    ? plans.filter((p) => p.id === subscription.plan.id)[0]
                        .color
                    : ""
                }
              >
                {subscription.plan.id} - &euro; {subscription.plan.amount / 100}
                /month
              </Tag>

              <br></br>

              <div>Current Billing Period:</div>
              <p>
                <Moment unix format="D MMM YYYY">
                  {subscription.current_period_start}
                </Moment>{" "}
                {" - "}
                <Moment unix format="D MMM YYYY">
                  {subscription.current_period_end}
                </Moment>
              </p>
              <div>Next Billing Date & Amount</div>
              {subscription.cancel_at ? (
                <p>There is no future billing date</p>
              ) : (
                <p>
                  {upcoming ? (
                    <>
                      <Moment unix format="DD MMMM YYYY">
                        {subscription.current_period_end}
                      </Moment>
                      - &euro; {upcoming.amount_due / 100}
                    </>
                  ) : (
                    <LoadingOutlined style={{ fontSize: 16 }} spin />
                  )}
                </p>
              )}
              <div>Subscription Status:</div>
              {subscription.cancel_at ? (
                <p>
                  Your subscription will cancel on{" "}
                  <Moment unix format="DD MMMM YYYY">
                    {subscription.cancel_at}
                  </Moment>
                  . No further action is required.
                </p>
              ) : (
                <div>
                  <p>{subscription.status}</p>
                </div>
              )}
              <Link to="/plans">
                <Button
                  type="primary"
                  style={{ marginRight: "10px" }}
                  key="console"
                >
                  Upgrade Plan
                </Button>
              </Link>
              {subscription.cancel_at ? (
                <Button onClick={handleDontCancel} disabled={loading}>
                  Don't Cancel{" "}
                  {loading ? (
                    <LoadingOutlined style={{ fontSize: 16 }} spin />
                  ) : (
                    ""
                  )}
                </Button>
              ) : (
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={handleCancelSubscription}
                  okText="Yes"
                  placement="bottom"
                  cancelText="No"
                >
                  <Button
                    htmlType="submit"
                    disabled={loading}
                    style={{ margin: "30px 0" }}
                  >
                    Cancel Subscription{" "}
                    {loading ? (
                      <LoadingOutlined style={{ fontSize: 16 }} spin />
                    ) : (
                      ""
                    )}
                  </Button>
                </Popconfirm>
              )}
              {error && <Alert message={error} type="error" />}
            </>
          ) : (
            <>
              {error ? (
                <Alert message={error} type="error" />
              ) : (
                <LoadingOutlined style={{ fontSize: 16 }} spin />
              )}
            </>
          )}
        </Card>
      </Col>
      <Col xl={12} md={24}>
        <Card title="Invoices" bordered={false}>
          <AuthUserContext.Consumer>
            {(authUser) => (
              <Invoices authUser={authUser} firebase={props.firebase} />
            )}
          </AuthUserContext.Consumer>
        </Card>
      </Col>
    </Row>
  );
};

export default BillingProfile;
